import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('global', {
    state: () => ({
        loading: false,
        perms: [],
        user: {},
        license: {},
        tokens: {},
    }),
    getters: {
        routeLoading: (state) => state.loading,
        get_perms: (state) => state.perms,
        get_user: (state) => state.user,
        get_license: (state) => state.license,
        get_tokens: (state) => state.tokens,
    },
    actions: {
        async toogleLoading() {
            this.loading = !this.loading;
        },
        async setPerms(perms) {
            this.perms = perms
        },
        async setLicense(license) {
            this.license = license
        },
        async setUser(user) {
            this.user = user;
        },
        async setTokens(tokens) {
            this.tokens = tokens;
        }
    }
})