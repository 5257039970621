import { http } from "@/plugins/axios";
import Router from "@/router/index";

async function get_or_create_local_storage(key, demo_data) {
    // Check localStorage
    let tmp_data = localStorage.getItem(key);

    // If localStorage empty, load demo data
    if (tmp_data == null) {
        // Save demo data
        localStorage.setItem(key, JSON.stringify(demo_data));

        return new Promise(function (resolve) {
            resolve(demo_data);
        });
    } else {
        return new Promise(function (resolve) {
            resolve(JSON.parse(tmp_data));
        });
    }
}

function get_object_from_local_storage(key, id) {
    let tmp_data = JSON.parse(localStorage.getItem(key));

    if (tmp_data == null) {
        return new Promise(function (resolve) {
            resolve(null);
        });
    }

    const data = tmp_data.find(x => x.id == id);

    return new Promise(function (resolve) {
        resolve(data);
    });
}


async function list(resource, name = null, params = {}, company = true) {
    let url = ''

    if (company) {
        url = "companies/" + Router.currentRoute.params?.companyId + "/"
    }

    if (typeof params !== "object") {
        params = {}
    }

    if (name != null) {
        params["name"] = name;
    }

    return http.get(url + resource, {
        params: params,
    });
}


function flatten_activity(activity) {
    const data = { ...activity }

    // Flatten data
    let controller = []
    let department = []
    let purposes = []
    let legal_basis = []
    let categories_personal_data = []
    let special_categories_personal_data = []
    let categories_data_subject = []
    let internal_receiver = []
    let external_receiver = []
    let measures = []
    let categories_receiver = []

    data.controller.forEach((item) => {
        controller.push(item.id)
    })
    data.department.forEach((item) => {
        department.push(item.id)
    })
    data.purposes.forEach((item) => {
        purposes.push(item.id)
    })
    data.legal_basis.forEach((item) => {
        legal_basis.push(item.id)
    })
    data.categories_receiver.forEach((item) => {
        categories_receiver.push(item.id);
    })
    data.categories_personal_data.forEach((item) => {
        let repositories = [];
        let deletion_period = [];

        item.repositories.forEach((repository) => {
            repositories.push(repository.id)
        });

        item.deletion_period.forEach((period) => {
            deletion_period.push(period.id)
        });

        categories_personal_data.push({
            id: item.id,
            description: item.description,
            repositories: repositories,
            deletion_period: deletion_period
        })
    })
    data.special_categories_personal_data.forEach((item) => {
        let repositories = [];
        let deletion_period = [];

        item.repositories.forEach((repository) => {
            repositories.push(repository.id)
        });

        item.deletion_period.forEach((period) => {
            deletion_period.push(period.id)
        });

        special_categories_personal_data.push({
            id: item.id,
            description: item.description,
            repositories: repositories,
            deletion_period: deletion_period
        })
    })
    data.categories_data_subject.forEach((item) => {
        categories_data_subject.push(item.id)
    })
    data.internal_receiver.forEach((item) => {
        internal_receiver.push(item.id)
    })
    data.external_receiver.forEach((item) => {
        const storage_locations = [];

        item.storage_locations.forEach((location) => {
            let appropriate_safeguards = [];

            location.appropriate_safeguards.forEach((safeguard) => {
                appropriate_safeguards.push(safeguard.id)
            });

            let location_data = {
                location: location.location.code,
                appropriate_safeguards: appropriate_safeguards,
            }

            storage_locations.push(location_data)
        });

        external_receiver.push({ id: item.external_data_receiver.id, storage_locations: storage_locations, description: item.description });
    })

    data.measures.forEach((item) => {
        measures.push({ id: item.id, risk_s: item.risk_s, risk_p: item.risk_p });
    })

    data.status = data.status != null ? data.status.id : null;
    data.controller = controller;
    data.department = department;
    data.purposes = purposes
    data.legal_basis = legal_basis
    data.categories_personal_data = categories_personal_data
    data.special_categories_personal_data = special_categories_personal_data
    data.categories_data_subject = categories_data_subject
    data.internal_receiver = internal_receiver
    data.external_receiver = external_receiver
    data.measures = measures
    data.categories_receiver = categories_receiver

    return data;
}

export { get_or_create_local_storage, flatten_activity, get_object_from_local_storage, list }