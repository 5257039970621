const routes = [
  {
    path: "/",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/DashboardView.vue"),
  },
  {
    path: "/personal-settings",
    name: "personal-settings",
    component: () =>
      import(/* webpackChunkName: "personal_settings" */ "@/views/PersonalSettingsView.vue"),
  },
  {
    path: "/my-tasks",
    name: "my-tasks",
    component: () =>
      import(/* webpackChunkName: "mytasks" */ "@/views/MyTasksView.vue"),
  },
  {
    path: "/companies/:companyId",
    name: "companie-dashboard",
    component: () =>
      import(
        /* webpackChunkName: "companie-dashboard" */ "@/views/company/DashboardView.vue"
      ),
  },
  {
    path: "/companies/:companyId/structure",
    name: "company-structure",
    component: () =>
      import(
        /* webpackChunkName: "companie-structure" */ "@/views/company/StructureView.vue"
      ),
  },
  {
    path: "/companies/:companyId/data",
    name: "company-data",
    component: () => import(/* webpackChunkName: "companie-data" */ '@/views/company/CoreDataView.vue')
  },
  {
    path: "/companies/:companyId/departments",
    name: "company-departments",
    component: () => import(/* webpackChunkName: "companie-departments" */ '@/views/departments/ListView.vue')
  },
  {
    path: "/companies/:companyId/departments/:id",
    name: "company-department-detail",
    component: () => import(/* webpackChunkName: "companie-department" */ '@/views/departments/DetailView.vue')
  },
  {
    path: "/companies/:companyId/resources",
    name: "company-resources",
    component: () => import(/* webpackChunkName: "concern-resources" */ '@/views/concern/ResourcesView.vue')
  },
  {
    path: "/companies/:companyId/resources/locations",
    name: "resource-location",
    component: () => import(/* webpackChunkName: "resource-location" */ '@/views/concern/LocationResource.vue')
  },
  {
    path: "/companies/:companyId/resources/purposes",
    name: "resource-purposes",
    component: () => import(/* webpackChunkName: "resource-purpose" */ '@/views/concern/PurposeResource.vue')
  },
  {
    path: "/companies/:companyId/resources/labels",
    name: "resource-labels",
    component: () => import(/* webpackChunkName: "resource-labels" */ '@/views/concern/LabelResource.vue')
  },
  {
    path: "/companies/:companyId/resources/authorities",
    name: "resource-authorities",
    component: () => import(/* webpackChunkName: "resource-authorities" */ '@/views/concern/SupervisoryAuthorityResource.vue')
  },
  {
    path: "/companies/:companyId/resources/categories-receiver",
    name: "resource-categories-receiver",
    component: () => import(/* webpackChunkName: "resource-category-receiver" */ '@/views/concern/CategoryReceiverResource.vue')
  },
  {
    path: "/companies/:companyId/resources/risk-sources",
    name: "resource-risk-sources",
    component: () => import(/* webpackChunkName: "resource-risk-source" */ '@/views/concern/RiskSourceResource.vue')
  },
  {
    path: "/companies/:companyId/resources/legal-basis",
    name: "resource-legal-basis",
    component: () => import(/* webpackChunkName: "resource-legal-basis" */ '@/views/concern/LegalBasisResource.vue')
  },
  {
    path: "/companies/:companyId/resources/personal-data",
    name: "resource-personal-data",
    component: () => import(/* webpackChunkName: "resource-personal-data" */ '@/views/concern/PersonalDataResource.vue')
  },
  {
    path: "/companies/:companyId/resources/special-personal-data",
    name: "resource-special-personal-data",
    component: () => import(/* webpackChunkName: "resource-special-personal-data" */ '@/views/concern/SpecialPersonalDataResource.vue')
  },
  {
    path: "/companies/:companyId/resources/data-subject",
    name: "resource-data-subject",
    component: () => import(/* webpackChunkName: "resource-data-subject" */ '@/views/concern/DataSubjectResource.vue')
  },
  {
    path: "/companies/:companyId/resources/time-limits",
    name: "resource-time-limits",
    component: () => import(/* webpackChunkName: "resource-time-limits" */ '@/views/concern/DeletionPeriodResource.vue')
  },
  {
    path: "/companies/:companyId/resources/measures",
    name: "resource-measures",
    component: () => import(/* webpackChunkName: "resource-measures" */ '@/views/concern/MeasureResource.vue')
  },
  {
    path: "/companies/:companyId/resources/repositories",
    name: "resource-repositories",
    component: () => import(/* webpackChunkName: "resource-measures" */ '@/views/concern/RepositoryResource.vue')
  },
  {
    path: "/companies/:companyId/resources/communication-types",
    name: "resource-communication-types",
    component: () => import(/* webpackChunkName: "resource-communication-types" */ '@/views/concern/ExternalCommunicationTypeResource.vue')
  },
  {
    path: "/companies/:companyId/resources/roles",
    name: "resource-roles",
    component: () => import(/* webpackChunkName: "resource-roles" */ '@/views/concern/RolesResource.vue')
  },
  {
    path: "/companies/:companyId/templates",
    name: "company-templates",
    component: () => import(/* webpackChunkName: "concern-templates" */ '@/views/concern/TemplatesView.vue')
  },
  {
    path: "/companies/:companyId/export-templates",
    name: "company-export-templates",
    component: () => import(/* webpackChunkName: "concern-export-templates" */ '@/views/concern/ExportTemplatesView.vue')
  },
  {
    path: "/companies/:companyId/central-user-management",
    name: "central-user-management",
    component: () => import(/* webpackChunkName: "central-user-management" */ '@/views/concern/central-user-management/ListView.vue')
  },
  {
    path: "/companies/:companyId/central-user-management/:id",
    name: "central-user-management-detail",
    component: () => import(/* webpackChunkName: "central-user-management-detail" */ '@/views/concern/central-user-management/DetailView.vue')
  },
  {
    path: "/companies/:companyId/export-templates/pdf",
    name: "company-export-templates-pdf",
    component: () => import(/* webpackChunkName: "concern-export-templates-pdf" */ '@/views/concern/ExportTemplatesPdfView.vue')
  },
  {
    path: "/companies/:companyId/export-templates/pdf/:id",
    name: "company-export-templates-pdf-detail",
    component: () => import(/* webpackChunkName: "concern-export-templates-pdf" */ '@/views/concern/ExportTemplatesPdfDetailView.vue')
  },
  {
    path: "/companies/:companyId/export-templates/word",
    name: "company-export-templates-word",
    component: () => import(/* webpackChunkName: "concern-export-templates-word" */ '@/views/concern/ExportTemplatesWordView.vue')
  },
  {
    path: "/companies/:companyId/templates/processing-activities",
    name: "template-processing-activities",
    component: () => import(/* webpackChunkName: "template-processing-activity" */ '@/views/concern/ProcessingActivityTemplate.vue')
  },
  {
    path: "/companies/:companyId/templates/processing-activities/:id/edit",
    name: "template-processing-activities-edit",
    component: () => import(/* webpackChunkName: "template-processing-activity-edit" */ '@/views/concern/ProcessingActivityEditTemplate.vue')
  },
  {
    path: "/companies/:companyId/templates/impact-assessment",
    name: "template-impact-assessment",
    component: () => import(/* webpackChunkName: "template-impact-assessment" */ '@/views/concern/ImpactAssessmentTemplate.vue')
  },
  {
    path: "/companies/:companyId/templates/impact-assessment/:id/edit",
    name: "template-impact-assessment-edit",
    component: () => import(/* webpackChunkName: "template-impact-assessment-edit" */ '@/views/concern/ImpactAssessmentEditTemplate.vue')
  },
  {
    path: "/companies/:companyId/templates/audits",
    name: "template-audits",
    component: () => import(/* webpackChunkName: "template-audits" */ '@/views/concern/AuditTemplates.vue')
  },
  {
    path: "/companies/:companyId/templates/audits/:id/edit",
    name: "template-audits-edit",
    component: () => import(/* webpackChunkName: "template-audits-edit" */ '@/views/concern/AuditEditTemplate.vue')
  },
  {
    path: "/companies/:companyId/templates/documents",
    name: "template-documents",
    component: () => import(/* webpackChunkName: "template-documents" */ '@/views/concern/DocumentTemplates.vue')
  },
  {
    path: "/companies/:companyId/templates/documents/:id/edit",
    name: "template-documents-edit",
    component: () => import(/* webpackChunkName: "template-documents-edit" */ '@/views/concern/DocumentTemplateEdit.vue')
  },
  {
    path: "/companies/:companyId/templates/checklisten",
    name: "template-checklists",
    component: () => import(/* webpackChunkName: "template-checklists" */ '@/views/concern/ChecklistTemplates.vue')
  },
  {
    path: "/companies/:companyId/templates/checklisten/:id/edit",
    name: "template-checklists-edit",
    component: () => import(/* webpackChunkName: "template-checklists-edit" */ '@/views/concern/ChecklistEditTemplate.vue')
  },
  {
    path: "/companies/:companyId/tasks",
    name: "company-tasks",
    component: () => import(/* webpackChunkName: "companie-tasks" */ '@/views/tasks/ListView.vue')
  },
  {
    path: "/companies/:companyId/subcontractors",
    name: "subcontractors",
    component: () => import(/* webpackChunkName: "company-subcontractors" */ '@/views/subcontractors/ListView.vue')
  },
  {
    path: "/companies/:companyId/subcontractors/:id",
    name: "subcontractors-detail",
    component: () => import(/* webpackChunkName: "company-subcontractors" */ '@/views/subcontractors/DetailView.vue')
  },
  {
    path: "/companies/:companyId/data-receiver",
    name: "company-data-receiver",
    component: () => import(/* webpackChunkName: "company-receivers" */ '@/views/data-receiver/ListView.vue')
  },
  {
    path: "/companies/:companyId/data-receiver/:id",
    name: "company-data-receiver-detail",
    component: () => import(/* webpackChunkName: "receiver-detail" */ '@/views/data-receiver/DetailView.vue')
  },
  {
    path: "/companies/:companyId/intercompany_agreement",
    name: "intercompany_agreement",
    component: () => import(/* webpackChunkName: "intercompany-agreement" */ '@/views/intercompany-agreement/ListView.vue')
  },
  {
    path: "/companies/:companyId/intercompany_agreement/:id",
    name: "intercompany_agreement-detail",
    component: () => import(/* webpackChunkName: "intercompany-agreement-detail" */ '@/views/intercompany-agreement/DetailView.vue')
  },
  {
    path: "/companies/:companyId/processing-activities",
    name: "company-documentation-processing-activity",
    component: () => import(/* webpackChunkName: "processing-activities" */ '@/views/processing-activity/ListView.vue')
  },
  {
    path: "/companies/:companyId/processing-activities/:id",
    name: "company-documentation-processing-activity-detail",
    component: () => import(/* webpackChunkName: "processing-activity-detail" */ '@/views/processing-activity/DetailView.vue')
  },
  {
    path: "/companies/:companyId/processing-activities/:id/versions",
    name: "processing-activity-versions",
    component: () => import(/* webpackChunkName: "processing-activity-versions" */ '@/views/processing-activity/VersionView.vue')
  },
  {
    path: "/companies/:companyId/processing-activities/:id/versions/:version_id",
    name: "processing-activity-versions-detail",
    component: () => import(/* webpackChunkName: "processing-activity-versions-detail" */ '@/views/processing-activity/VersionDetailView.vue')
  },
  {
    path: "/companies/:companyId/processing-activities/:id/edit/:step?",
    name: "company-documentation-processing-activity-edit",
    component: () => import(/* webpackChunkName: "processing-activity-edit" */ '@/views/processing-activity/EditView.vue')
  },
  {
    path: "/companies/:companyId/processing-on-behalf",
    name: "company-documentation-processing-on-behalf",
    //component: () => import(/* webpackChunkName: "demo-view" */ '@/views/DemoView.vue')
    component: () => import(/* webpackChunkName: "processing-activities" */ '@/views/processing-on-behalf/ListView.vue')
  },
  {
    path: "/companies/:companyId/processing-on-behalf/:id/edit/:step?",
    name: "company-documentation-processing-on-behalf-edit",
    component: () => import(/* webpackChunkName: "processing-activity-edit" */ '@/views/processing-on-behalf/EditView.vue')
  },
  {
    path: "/companies/:companyId/processing-on-behalf/:id",
    name: "company-documentation-processing-on-behalf-detail",
    component: () => import(/* webpackChunkName: "processing-activity-detail" */ '@/views/processing-on-behalf/DetailView.vue')
  },
  {
    path: "/companies/:companyId/measures",
    name: "company-documentation-tom",
    component: () => import(/* webpackChunkName: "measures" */ '@/views/measure/ListView.vue')
  },
  {
    path: "/companies/:companyId/erasure-concept",
    name: "company-documentation-deletion",
    component: () => import(/* webpackChunkName: "deletion-concept" */ '@/views/erasure-concept/ListView.vue')
  },
  {
    path: "/companies/:companyId/impact-assessment",
    name: "company-documentation-impact-assessment-list",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "manage-companie" */ '@/views/impact-assessment/ListView.vue')
  },
  {
    path: "/companies/:companyId/impact-assessment/:id/edit/:step?",
    name: "company-documentation-impact-assessment-edit",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "manage-companie" */ '@/views/impact-assessment/EditView.vue')
  },
  {
    path: "/companies/:companyId/impact-assessment/:id",
    name: "company-documentation-impact-assessment-detail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "manage-companie" */ '@/views/impact-assessment/DetailView.vue')
  },
  {
    path: "/companies/:companyId/external-correspondence",
    name: "company-documentation-external-communication",
    component: () => import(/* webpackChunkName: "demo-view" */ '@/views/external-communication/ListView.vue')
  },
  {
    path: "/companies/:companyId/external-correspondence/:id",
    name: "company-documentation-external-communication-detail",
    component: () => import(/* webpackChunkName: "demo-view" */ '@/views/external-communication/DetailView.vue')
  },
  {
    path: "/companies/:companyId/audits",
    name: "company-documentation-audits",
    component: () => import(/* webpackChunkName: "audits-overview" */ '@/views/audits/AuditsView.vue')
  },
  {
    path: "/companies/:companyId/audits/:type/",
    name: "company-documentation-audits-list",
    component: () => import(/* webpackChunkName: "audits-type" */ '@/views/audits/ListView.vue')
  },
  {
    path: "/companies/:companyId/audits/:type/:id",
    name: "company-documentation-audit-detail",
    component: () => import(/* webpackChunkName: "audits-type-detail" */ '@/views/audits/DetailView.vue')
  },
  {
    path: "/companies/:companyId/audits/:type/:id/edit",
    name: "company-documentation-audit-edit",
    component: () => import(/* webpackChunkName: "audits-type-edit" */ '@/views/audits/EditView.vue')
  },
  {
    path: "/companies/:companyId/checklists",
    name: "company-documentation-checklists",
    component: () => import(/* webpackChunkName: "demo-view" */ '@/views/checklists/ListView.vue')
  },
  {
    path: "/companies/:companyId/checklists/:id/edit",
    name: "company-documentation-checklist-edit",
    component: () => import(/* webpackChunkName: "demo-view" */ '@/views/checklists/EditView.vue')
  },
  {
    path: "/companies/:companyId/checklists/:id",
    name: "company-documentation-checklist-detail",
    component: () => import(/* webpackChunkName: "manage-companie" */ '@/views/checklists/DetailView.vue')
  },
  {
    path: "/companies/:companyId/data-breach",
    name: "company-documentation-data-breach",
    component: () => import(/* webpackChunkName: "demo-view" */ '@/views/data-breach/ListView.vue')
  },
  {
    path: "/companies/:companyId/data-breach/:id/edit/:step?",
    name: "company-documentation-data-breach-edit",
    component: () => import(/* webpackChunkName: "demo-view" */ '@/views/data-breach/EditView.vue')
  },
  {
    path: "/companies/:companyId/data-breach/:id",
    name: "company-documentation-data-breach-detail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "manage-companie" */ '@/views/data-breach/DetailView.vue')
  },
  {
    path: "/companies/:companyId/reports",
    name: "company-reports",
    component: () => import(/* webpackChunkName: "report-view" */ '@/views/reports/ListView.vue')
  },
  {
    path: "/companies/:companyId/generator",
    name: "company-generator",
    component: () => import(/* webpackChunkName: "document-generator" */ '@/views/document-generator/ListView.vue')
  },
  {
    path: "/companies/:companyId/generator/:id/edit",
    name: "company-generator-edit",
    component: () => import(/* webpackChunkName: "document-generator" */ '@/views/document-generator/EditView.vue')
  },
  {
    path: "/companies/:companyId/repositories",
    name: "company-repositories",
    component: () => import(/* webpackChunkName: "demo-view" */ '@/views/repository/ListView.vue')
  },
  {
    path: "/companies/:companyId/controller",
    name: "company-controller",
    component: () => import(/* webpackChunkName: "controller-view" */ '@/views/controller/ListView.vue')
  },
  {
    path: "/companies/:companyId/controller/:id",
    name: "company-controller-detail",
    component: () => import(/* webpackChunkName: "controller-detail" */ '@/views/controller/DetailView.vue')
  },
  {
    path: "/companies/:companyId/settings",
    name: "company-settings",
    component: () => import(/* webpackChunkName: "settings-view" */ '@/views/company/SettingsView.vue')
  },
  {
    path: "/companies/:companyId/settings/user-management",
    name: "company-settings-user-management",
    component: () => import(/* webpackChunkName: "user-management-view" */ '@/views/company/user-management/ListView.vue')
  },
  {
    path: "/companies/:companyId/settings/user-management/:id",
    name: "company-settings-user-management-detail",
    component: () => import(/* webpackChunkName: "user-management-detail" */ '@/views/company/user-management/DetailView.vue')
  },
  {
    path: "/companies/:companyId/settings/design",
    name: "company-settings-design",
    component: () => import(/* webpackChunkName: "design-view" */ '@/views/company/DesignView.vue')
  },
];

export default routes;
